<template>
  <div class="page-content" v-if="item">
    <page-breadcrumb title="Exam Detail" class="mb-2" :items="breadcrumbItems" />
    <section class="mb-2">
      <b-card class="mt-1">
        <form-generator :model="item" :schema="item_form_schema" />
        <!-- <div v-if="item.type == 1">
          <h4>Recommend Courses</h4>
          <dynamic-zone ref="map_list" item-collapse="last" :items="item.recommend_courses" title_field="title"
            :default-data="{ title: '', description: translatableDefaultObject }" :enable-create-item="true">
            <template v-slot="{ item }">
              <form-generator :model="item" :schema="[
                { cols: 12, fields: [{ field: 'title', label: 'Title', validate: { required: true } }] },
                { cols: 12, fields: [{ field: 'description', label: 'Description', input_type: 'ui-component' }] },
              ]" />
            </template>
          </dynamic-zone>
        </div> -->
      </b-card>
    </section>
    <action-buttons update-button back-button delete-button @update="update" :is-updating="isUpdating"
      @delete="deleteItem" :is-deleting="isDeleting" />
  </div>
</template>
<script>
const level_type_opts = [
  { text: "Entry test", value: 1 },
  { text: "Vocabulary", value: 2 },
  { text: "Listening", value: 3 },
  { text: "Speaking", value: 4 },
];
const english_level_opts = [];

const item_form_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Type', field: 'type', input_type: 'select', options: level_type_opts, disabled: true },
      { label: 'English level', field: 'english_level', input_type: 'select', options: english_level_opts, disabled: false },
      { label: 'Title', field: 'title', input_type: 'ui-component', validate: { required: true } },
      // { label: 'Text', field: 'level_text', input_type: 'ui-component', validate: { required: true } },
      { label: 'Min Score', field: 'min_score', input_type: 'nummer', validate: { required: true } },
      { label: 'Max Score', field: 'max_score', input_type: 'nummer', validate: { required: true } },
      { label: 'What you can do Title', field: 'what_can_do_title', input_type: 'ui-component', validate: { required: true } },
      { label: 'What you can do Content', field: 'what_can_do_content', input_type: 'ui-component', validate: { required: true } },
      { label: 'Next Step Title', field: 'next_step_title', input_type: 'ui-component', validate: { required: true } },
      { label: 'Next Step Content', field: 'next_step_content', input_type: 'ui-component', validate: { required: true } },
    ]
  }
];
const translatableDefault = {
  type: 'translatable-richtext',
  meta: {
    default_lang: 'vi'
  },
  value: '',
  translations: [
    {
      language: 'vi',
      value: ''
    }
  ]
}
import { buildSelectOptions, safeGet } from '@core/utils/index';
import service from '../service';
export default {
  data() {
    return {
      item_form_schema,
      item: null,
      isUpdating: false,
      isDeleting: false,
      translatableDefaultObject: JSON.parse(JSON.stringify(translatableDefault)),
    }
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
    breadcrumbItems() {
      let typeText = level_type_opts.find(opt => opt.value === this.item.type)
      let items = [
        {
          text: 'Exam Levels',
          to: { name: 'goga-exam-level-list' },
        },
        {
          text: `${typeText.text}`, active: true
        },
      ]
      return items
    },
    authLanguage() {
      return this.$store.getters["auth/language"];
    },
  },
  created() {
    this.getExamLevel()
  },
  methods: {
    async getExamLevel() {
      let response_data = await service.get({ id: this.itemId });
      if(response_data) {
        this.item = response_data.document;
        let levels = safeGet(() => buildSelectOptions(response_data.meta['ENGLISH_LEVEL'])) || [];
        english_level_opts.length = 0;
        english_level_opts.push(...levels);
      }
    },
    async update() {
      this.isUpdating = true;
      await service.update({
        data: JSON.stringify(this.item),
      });
      this.getExamLevel();
      this.isUpdating = false;
    },
    async deleteItem() {
      await service.delete({ id: this.itemId });
      this.$router.push({ name: "exam-level-list" });
    },
  }
}
</script>
